<template lang="pug">
#mainContainer
  router-view

</template>

<script setup>
import { useRouter } from 'vue-router'

const router = useRouter()
</script>

<style lang="less" scoped>
#mainContainer {
  min-width: 100vw;
  min-height: 100vh;
}
</style>
